<template>
  <div>
    <b-modal
      v-model="showActionModal"
      :cancel-title="$t('global.cancel')"
      :ok-title="$t('global.yes')"
      @ok="userAction(action, false, true)"
    >
      {{ modalText}}
    </b-modal>

    <b-modal
      v-model="showAddUserModal"
      centered
      :cancel-title="$t('global.cancel')"
      :ok-title="$t('global.submit')"
      @ok="addUser"
    >
      <div>
        <h1 class="d-flex justify-content-center mb-2">
          {{ $t("global.add") }}
        </h1>
        <div class="mb-1">
          <!-- basic -->

          <b-form-group :label="$t('users.firstName')">
            <b-form-input
              v-model="addUserData.firstName"
              :placeholder="$t('users.firstName')"
            />
          </b-form-group>
        </div>
        <div class="mb-1">
          <!-- basic -->
          <b-form-group :label="$t('users.lastName')">
            <b-form-input
              v-model="addUserData.lastName"
              :placeholder="$t('users.firstName')"
            />
          </b-form-group>
        </div>
        <div class="mb-1">
          <!-- basic -->
          <b-form-group :label="$t('users.email')">
            <b-form-input
              v-model="addUserData.email"
              :placeholder="$t('users.email')"
            />
          </b-form-group>
        </div>
        <div class="mb-1">
          <!-- basic -->
          <b-form-group :label="$t('users.mobile')">
            <b-form-input
              v-model="addUserData.mobile"
              :placeholder="$t('users.mobile')"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>

    <b-card
      :header="$t('navigation.user-management')"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-button variant="primary" class="mb-2" @click="showAddUserPopup">
        {{ $t("global.add") }}
      </b-button>
      <!-- filters and search -->
      <FiltersAndSearch
        v-if="false"
        :id.sync="id"
        :searchKey.sync="searchKey"
        :activeOnly.sync="activeOnly"
        :sortOrder.sync="sortOrder"
      />
      <!-- end of filters and search -->
      <!-- table -->
      <b-table
        sticky-header
        :fields="fields"
        :items="users"
        bordered
        responsive
        hover
        class="products-table shadow-sm rounded"
        show-empty
        empty-text="No matching records found"
        small
      >
        <template #cell(action)="data">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            @click="userAction('reset', data.item, false)"
          >
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="userAction('delete', data.item, false)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <Pagination
        :fetch-data="handleGettingUsers"
        :total-rows="totalRows"
        :take.sync="take"
        :skip.sync="skip"
      />
    </b-card>
  </div>
</template>

<script>
import { BFormGroup, BTable, BFormInput, BButton, BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import { required, email } from "@validations";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FiltersAndSearch from "@/views/components/FiltersAndSearch/FiltersAndSearch.vue";
import Pagination from "@/views/components/Pagination/Pagination.vue";

export default {
  components: {
    BTable,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    FiltersAndSearch,
    Pagination,
  },
  data() {
    return {
      required,
      email,
      dir: false,
      showActionModal: false,
      showAddUserModal: false,
      modalText: "",
      action: null,
      resetUserId: null,
      addUserData: {},
      sortOrder: 2,
      id: "",
      searchKey: "",
      activeOnly: true,
      totalRows: 0,
      take: 20,
      skip: 0,
    };
  },
  computed: {
    ...mapState("userManagement", ["users"]),
    fields() {
      return [
        {
          label: this.$t("global.action"),
          key: "action",
        },
        {
          label: "ID",
          key: "displayId",
        },
        {
          label: this.$t("users.firstName"),
          key: "firstName",
        },
        {
          label: this.$t("users.lastName"),
          key: "lastName",
        },
        {
          label: this.$t("users.email"),
          key: "email",
        },
        {
          label: this.$t("users.mobile"),
          key: "mobile",
        },
      ];
    },
  },
  watch: {
    searchKey() {
      this.handleGettingUsers();
    },
    activeOnly() {
      this.handleGettingUsers();
    },
    id() {
      this.handleGettingUsers();
    },
    sortOrder() {
      this.handleGettingUsers();
    },
    take() {
      this.handleGettingUsers();
    },
  },

  created() {
    this.handleGettingUsers();
  },
  methods: {
    ...mapActions("userManagement", [
      "getUsers",
      "registerUser",
      "resetUserPassword",
      "deleteUser",
    ]),
    async handleGettingUsers() {
      try {
        await this.getUsers({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          id: this.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
    showPopup() {
      this.showActionModal = true;
    },
    showAddUserPopup() {
      this.showAddUserModal = true;
    },
    toast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async addUser() {
      try {
        await this.registerUser({
          firstName: this.addUserData.firstName,
          lastName: this.addUserData.lastName,
          email: this.addUserData.email,
          mobile: this.addUserData.mobile,
        });
        this.toast("User added successfully", "success");
        this.addUserData = {};
        this.showAddUserModal = false;
        this.getUsers();
      } catch (error) {
        console.log(error);
      }
    },
    userAction(action, item, ok) {
      switch (action) {
        case "reset":
          if (item.id) {
            this.modalText = `${item.email} - ${this.$t("users.resetPassword")}`;
            this.resetUserId = item.id;
            this.action = "reset";
          }
          if (ok) {
            this.resetUserPassword({
              id: this.resetUserId,
            });
            this.resetUserId = null;
            this.action = null;
          } else {
            this.showPopup();
          }
          break;
        case "delete":
          if (item.id) {
            this.modalText = `${item.email} - ${this.$t("users.deleteUser")}`;
            this.action = "delete";
            this.resetUserId = item.id;
          }
          if (ok) {
            this.deleteUser({
              id: this.resetUserId,
            });
            this.users.splice(
              this.users.findIndex((i) => i.id === this.resetUserId),
              1
            );
            // this.adminAct = null;
          } else {
            this.showPopup();
          }
          break;
        default:
        // code block
      }
    },
  },
};
</script>
<style lang="scss">
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}

.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
